<template src="./index.html"></template>
<style scoped src="./index.css"></style>
<script>
import { mapState } from "vuex";
import { regular } from "@/utils/validate.js";
import provinceCity from "../../static/locationOption.json";

export default {
  computed: {
    ...mapState({
      express_list: (state) => state.setting.express_list,
      info_Invoice: (state) => state.setting.invoice_list,
      config_info: (state) => state.setting.config_info,
    }),
  },
  data() {
    return {
      loadingCity: false,
      loadingDistrict: false,
      provinces: [],
      cities: [],
      districts: [],
      order: {
        order_sort: 4,
        express_sort: 1,
        invoice_sort: 1,
      },
      wordFilter: {},
      rule: {
        name: [
          { required: true, message: "请输入发件人姓名", trigger: "blur" },
          {
            min: 2,
            max: 11,
            message: "长度在 2 到 11 个字符",
            trigger: "blur",
          },
        ],
        phone: [
          { required: true, message: "请输入正确的电话号码", trigger: "blur" },
          {
            pattern: regular.mobile,
            message: regular.mobileMsg,
            trigger: "blur",
          },
        ],
        province: [
          {
            required: true,
            message: "请选择地址",
            trigger: ["blur", "change"],
          },
        ],
        city: [
          {
            required: true,
            message: "请选择地址",
            trigger: ["blur", "change"],
          },
        ],
        district: [
          { required: true, message: "请选择地址", trigger: "blur" },
          {
            // required: true,
            message: "请选择地址",
            trigger: ["blur", "change"],
          },
        ],
        address: [{ required: true, message: "请输入详细地址", trigger: "blur" }],
        title: [
          { required: false, message: "请输入标题", trigger: "blur" },
          {
            min: 2,
            max: 11,
            message: "长度在 2 到 11 个字符",
            trigger: "blur",
          },
        ],
        operator: [
          { required: false, message: "请输入经办人姓名", trigger: "blur" },
          {
            min: 2,
            max: 11,
            message: "长度在 2 到 11 个字符",
            trigger: "blur",
          },
        ],
        shop_name: [
          { required: false, message: "请输入店铺名称", trigger: "blur" },
          {
            min: 2,
            max: 11,
            message: "长度在 2 到 11 个字符",
            trigger: "blur",
          },
        ],
      },
      invoicerule: {},
      checked: false,
      getMessageChecked: false,
    };
  },

  methods: {
    provinceLoad() {
      this.provinces = provinceCity[0];
    },
    provinceChanged(code,index) {
      if (code != "") {
        var str = "0," + code;
        this.loadingCity = true;
        this.cities = provinceCity[str];
        this.express_list[index].province_code = code;
        this.express_list[index].province = provinceCity[0][code];
        this.loadingCity = false;
        this.nullcity(index);
        this.nulldistrict(index);
      }
    },
    cityLoad(index) {
      var str = "0," + this.express_list[index].province_code;
      this.cities = provinceCity[str];
    },
    cityChanged(code,index) {
      if (code != "") {
        var str = "0," + this.express_list[index].province_code + "," + code;
        this.loadingDistrict = true;
        this.districts = provinceCity[str];
        this.express_list[index].city_code = code;
        this.express_list[index].city = this.cities[code];
        this.loadingDistrict = false;
        this.nulldistrict(index);
      }
    },
    districtLoad(index) {
      var str = "0," + this.express_list[index].province_code + "," + this.express_list[index].city_code;
      this.districts = provinceCity[str];
    },
    districtChanged(code,index) {
      if (code != "") {
        this.loadingDistrict = true;
        var str = "0," + this.express_list[index].province_code + "," + this.express_list[index].city_code;
        this.express_list[index].district = provinceCity[str][code];
        this.express_list[index].district_code = code;
        this.express_list[index].post_code = code;
        this.loadingDistrict = false;
      }
    },
    nullcity(index) {
      this.express_list[index].city_code = "";
      this.express_list[index].city = "";
    },
    nulldistrict(index) {
      this.express_list[index].district = "";
      this.express_list[index].district_code = "";
    },
    saveEpress(params, api, formName) {
      var url = "setting/" + api;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if(this.info_Invoice.phone.length>=1 && this.info_Invoice.phone.length<11){
            this.$message({ message: "联系电话不能小于11位", type: "error" });
            return false
          }
          this.$store.dispatch(url, params).then((res) => {
            if (res.code == 0) {
              this.$message({ message: "保存成功", type: "success" });
            } else {
              // this.$message({ message: res.message, type: "success" });
            }
          });
        } else {
          return false;
        }
      });
    },
    // 保存订单显示顺序
    save_order_sort(){
      let params={
        pagesize:localStorage.getItem('pageSize')?localStorage.getItem('pageSize'):'30',
        auto_merge:localStorage.getItem('autoMerge'),
        priority_merge:localStorage.getItem('priorityMerge'),
        order_sort: this.config_info.order_sort
      }
      this.$store.dispatch("setting/editConfig", params).then((res) => {
        if (res.code == 0) {
          this.$message({ message: "保存成功", type: "success" });
        } else {
          this.$message({ message: res.message, type: "error" });
        }
      });
    },
    // 保存发件人列表
    saveExpressSenderList(){
      let from_verify_error = false
      for(let i in this.express_list){
        let refs_name = "expresslistFrom_"+i
        this.$refs[refs_name][0].validate((valid) => {
          if (!valid) {
            from_verify_error = true
          }
        });
      }
      if(from_verify_error){
        return false
      }else{
        this.$store.dispatch("setting/editAddressExpress", this.express_list).then((res) => {
            if (res.code == 0) {
              this.$message({ message: "保存成功", type: "success" });
            } else {
              this.$message({ message: res.message, type: "success" });
            }
          });
      }
    },
    // 获取配置信息
    async getSetting() {
      let that = this;
      await this.$store.dispatch("setting/getConfig").then((res) => {
        // that.info_Invoice=that.info_Invoice[0]
      //  console.log(res,'resres');
      });
    },
    addSendBtn() {
      this.express_list.push({
        address: "",
        city: "",
        city_code: "",
        ctime: "",
        district: "",
        district_code: "",
        id: "",
        is_del: "",
        mtime: "",
        name: "",
        phone: "",
        post_code: "",
        province: "",
        province_code: "",
        shop_id: "",
        sign_name: "",
      });
    },
    deleteBtn(index) {
      if(this.express_list.length>1){
        this.express_list.splice(index, 1);
      }else{
        this.$message({ message:"请至少保留一个快递单发件人", type: "warning" });
      }
    },
  },
  created() {
    this.getSetting();
  },
  saveOrderSort(){
    
  }
};
</script>
